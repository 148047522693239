<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2>
        </b-link>
        <b-card-title class="mb-1">
          Welcome to Vuexy! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please Enter Your Pin Code
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="SendPin"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- pin -->
            <b-form-group
              label-for="pin_code"
              label="Pin Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Pin Code"
                rules="required|min:4"
              >
                <b-form-input
                  id="pin_code"
                  v-model="pin_code"
                  name="pin_code"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Enter Pin"
                  autofocus
                  maxlength="16"
                  @keypress="onlyNumbers($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="pin_code_confirmation">Confirm Pin</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Pin Code Confirmation"
                rules="required|min:4"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="pin_code_confirmation"
                    v-model="pin_code_confirmation"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="pin_code_confirmation"
                    placeholder="Pin Code Confirmation"
                    maxlength="16"
                    @keypress="onlyNumbers($event)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
              v-if="!pending"
              variant="primary"
              type="submit"
              block
              @click="submit"
            >
              Set Pin
            </b-button>
            <b-button
              v-if="pending"
              variant="primary"
              block
              disabled
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>

import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BSpinner,

} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  required, between, digits, min,
} from '@validations'
// import { mapActions } from 'vuex'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'

const userProfile = new ProfileApis()
export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BSpinner,
  },
  mixins: [alphanumeric],
  data() {
    return {
      pin_code: '',
      pin_code_confirmation: '',
      email: this.$route.query.email,
      token: this.$route.query.token,
      status: '',
      required,
      between,
      digits,
      min,
      pending: false,
    }
  },
  methods: {
    async submit() {
      const data = {
        token: this.token,
        email: this.email,
        pin_code: this.pin_code,
        pin_code_confirmation: this.pin_code_confirmation,
      }
      this.pending = true
      const response = await userProfile(data)
      this.showReaction(response)
      this.pending = false
      if (response.status === 422) {
        this.$router.push(`/${this.$i18n.locale}/token-expired`)
      } else if (response.status === 201) {
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
